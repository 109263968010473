import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/interfaces';
import { Observable } from 'rxjs';
import { GeneratedReply } from './AI.models';

@Injectable({
  providedIn: 'root',
})
export class AIService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {}

  // This is where AI messages get generated in the Inbox.
  generateReply(threadUuid: string, hostUid: string, generatedReplyUuid: string | null): Observable<ApiResponse<GeneratedReply>> {
    return this.http.post<ApiResponse<GeneratedReply>>(
      `${this.apiUrl}/threads/${threadUuid}/generate-reply`,
      {
        host_id: hostUid,
        generated_reply_id: generatedReplyUuid,
      }
    );
  }

  // This is where AI messages get improved in the Inbox.
  generateImprovement(threadUuid: string, message: string): Observable<ApiResponse<GeneratedReply>> {
    return this.http.post<ApiResponse<GeneratedReply>>(`${this.apiUrl}/threads/${threadUuid}/generate-improvement`, {
      message,
    });
  }
}
