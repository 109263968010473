<div class="flex flex-col h-full">
  <!-- Header -->
  <div class="flex justify-between items-center mb-6">
    <h2 class="text-xl font-semibold flex items-center gap-2 mb-0">
      <sbnb-icon icon="magic" [size]="24"></sbnb-icon>
      <span>Copilot</span>
    </h2>
    <sbnb-close [border]="true" (click)="close()"></sbnb-close>
  </div>

  <!-- Split View Container -->
  <div class="flex flex-1 gap-6 overflow-hidden">
    <!-- Left Side: Conversations List -->
    <div class="w-1/3 flex flex-col overflow-hidden">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-sm font-medium text-gray-700">Conversations</h3>
        <sbnb-button label="New" size="mini" (clicked)="startNewChat()"></sbnb-button>
      </div>

      <div class="flex-1 overflow-y-auto" data-testid="copilot-conversations-list">
        <ng-container *ngIf="conversations$ | async as conversationsResponse">
          <div
            *ngFor="let conversation of conversationsResponse.data"
            class="mb-3 p-3 border border-gray-200 rounded-lg hover:bg-gray-50 cursor-pointer relative"
            [class.bg-gray-50]="currentConversationUuid === conversation.uuid"
            (click)="openConversation(conversation)"
            data-testid="copilot-conversation-item">
            <div class="flex justify-between items-start">
              <div class="flex flex-col gap-1">
                <h3 class="font-medium text-gray-900 text-sm">{{ conversation.description }}</h3>
                <span class="text-xs text-gray-500">{{ conversation.updated_at | date: 'medium' }}</span>
              </div>
              <button
                class="text-gray-400 hover:text-gray-600 transition-colors p-1 rounded-full hover:bg-gray-100"
                (click)="toggleDropdown($event, conversation.uuid)"
                data-testid="conversation-menu-button">
                <sbnb-icon icon="more" [size]="16"></sbnb-icon>
              </button>
            </div>

            <!-- Dropdown Menu -->
            <div
              *ngIf="openDropdownId === conversation.uuid"
              class="absolute right-2 top-10 bg-white shadow-lg rounded-md py-1 z-10 min-w-[150px] border border-gray-200"
              data-testid="conversation-dropdown-menu">
              <button
                class="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50 flex items-center gap-2"
                (click)="deleteConversation($event, conversation)"
                data-testid="delete-conversation-button">
                <sbnb-icon icon="trash" [size]="16"></sbnb-icon>
                <span>Delete</span>
              </button>
              <!-- Additional options can be added here in the future -->
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Right Side: Chat View -->
    <div class="flex-1 flex flex-col overflow-hidden border-l border-gray-200 pl-6">
      <!-- Chat Header -->
      <div class="mb-4">
        <h3 class="font-medium text-gray-900">
          {{ (currentConversation$ | async)?.description || 'New Conversation' }}
        </h3>
        <sbnb-cs-debug-item
          *sbnbCSDebug="(currentConversation$ | async) !== null"
          label="copilot_conversation_uuid"
          [value]="(currentConversation$ | async)?.uuid"></sbnb-cs-debug-item>
      </div>

      <!-- Loading State -->
      <div *ngIf="isLoading" class="flex-1 flex justify-center items-center">
        <div class="flex items-center gap-2">
          <div class="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style="animation-delay: 0ms"></div>
          <div class="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style="animation-delay: 150ms"></div>
          <div class="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style="animation-delay: 300ms"></div>
          <span class="ml-2 text-gray-600">Loading conversation...</span>
        </div>
      </div>

      <ng-container *ngIf="!isLoading">
        <!-- Conversation History -->
        <ng-container
          *ngIf="
            currentConversation$ | async | fn: this.copilotService.transformConversationToDisplayFormat as conversation
          ">
          <div class="flex-1 overflow-y-auto space-y-6 mb-6" data-testid="copilot-conversation" #conversationContainer>
            <!-- Messages -->
            <div *ngFor="let item of conversation">
              <!-- User Message -->
              <div class="flex justify-end">
                <div class="max-w-[80%]">
                  <div class="bg-gray-600 text-white px-4 py-2 pr-7 rounded-lg whitespace-pre-wrap relative group">
                    <div class="leading-snug">{{ item.question }}</div>
                    <div class="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                      <sbnb-copy-to-clipboard
                        [content]="item.question"
                        [mini]="true"
                        data-testid="copy-user-message"></sbnb-copy-to-clipboard>
                    </div>
                    <sbnb-cs-debug-item
                      *sbnbCSDebug="item.userMessageUuid !== null"
                      label="copilot_message_uuid"
                      [value]="item.userMessageUuid"></sbnb-cs-debug-item>
                  </div>
                </div>
              </div>

              <!-- Assistant Message -->
              <div class="flex justify-start mt-6" *ngIf="item.response">
                <div class="max-w-[80%]">
                  <div class="flex flex-col gap-2">
                    <div *ngIf="!item.error" class="bg-gray-100 px-4 py-2 pr-5 rounded-lg text-gray-700 relative group">
                      <div class="space-y-4" [innerHTML]="item.response | markdown | safe: 'html'"></div>
                      <div class="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <sbnb-copy-to-clipboard
                          [content]="item.response"
                          [mini]="true"
                          data-testid="copy-assistant-message"></sbnb-copy-to-clipboard>
                      </div>
                      <ng-container *ngIf="(csService.isCSDebug$ | async) && item.assistantMessageUuid">
                        <sbnb-cs-debug-item
                          label="copilot_message_uuid"
                          [value]="item.assistantMessageUuid"></sbnb-cs-debug-item>
                      </ng-container>
                      <!-- Feedback Buttons -->
                      <div class="flex items-center gap-2 mt-2 border-t border-gray-200 pt-2">
                        <button
                          (click)="onFeedbackClick(CopilotFeedbackRating.LIKE, item, $event)"
                          class="p-1 rounded hover:bg-gray-200 transition-colors text-gray-300"
                          [class.text-green-500]="item.feedback === CopilotFeedbackRating.LIKE"
                          [class.cursor-pointer]="item.feedback === undefined"
                          [disabled]="item.feedback !== undefined"
                          data-testid="copilot-like-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" />
                          </svg>
                        </button>
                        <button
                          (click)="onFeedbackClick(CopilotFeedbackRating.DISLIKE, item, $event)"
                          class="p-1 rounded hover:bg-gray-200 transition-colors text-gray-300"
                          [class.text-red-500]="item.feedback === CopilotFeedbackRating.DISLIKE"
                          [class.cursor-pointer]="item.feedback === undefined"
                          [disabled]="item.feedback !== undefined"
                          data-testid="copilot-dislike-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-start">
              <!-- Progress Message-->
              <div *ngIf="progressMessage$ | async">
                <div class="bg-gray-100 px-4 py-2 rounded-lg text-gray-600">
                  <div class="flex items-center gap-2">
                    <div class="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style="animation-delay: 0ms"></div>
                    <div class="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style="animation-delay: 150ms"></div>
                    <div class="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style="animation-delay: 300ms"></div>
                    <span class="ml-2">
                      {{ progressMessage$ | async }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Error Message -->
              <div
                *ngIf="
                  (currentConversation$ | async)?.status === 'error' ||
                  (currentConversation$ | async)?.messages[conversation.length - 1]?.status === 'error'
                ">
                <div class="max-w-[80%]">
                  <div class="flex flex-col gap-2">
                    <div class="bg-red-100 px-4 py-2 rounded-lg text-red-700" data-testid="copilot-error-message">
                      <div class="flex gap-4 items-center">
                        <sbnb-icon icon="error" [size]="20" class="text-red-700 shrink-0"></sbnb-icon>
                        <div class="flex flex-col">
                          <span>
                            Oops, seems like an error occurred. I was unable to process your request at this time.
                          </span>
                          <div
                            *ngIf="(currentConversation$ | async)?.status === 'error'"
                            class="flex items-center gap-2 mt-2 pt-2 border-t border-red-200">
                            <button
                              (click)="retryQuestion(conversation[conversation.length - 1])"
                              class="flex items-center gap-1 text-xs text-red-700 hover:text-red-800 transition-colors"
                              data-testid="retry-question-button">
                              <sbnb-icon icon="sync" [size]="12"></sbnb-icon>
                              <span>Retry</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Question Suggestions -->
          <div class="mt-auto">
            <div class="flex flex-col gap-4">
              <div
                *ngIf="showSuggestions && questionSuggestions.length > 0 && conversation.length === 0"
                class="flex flex-col gap-2 mr-2"
                data-testid="copilot-suggestions">
                <div class="flex flex-col gap-2 items-end">
                  <button
                    *ngFor="let suggestion of questionSuggestions"
                    (click)="selectSuggestion(suggestion)"
                    class="inline-flex px-4 py-2 text-sm rounded-full border border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-colors text-gray-700 ring-1 ring-gray-300"
                    data-testid="copilot-suggestion-item">
                    <span class="whitespace-normal text-right">{{ suggestion }}</span>
                  </button>
                  <button
                    (click)="loadQuestionSuggestions()"
                    class="flex items-center gap-1 hover:bg-gray-50 p-1 rounded transition-colors"
                    data-testid="refresh-suggestions-button">
                    <sbnb-icon icon="sync" [size]="16" class="text-gray-500"></sbnb-icon>
                    <span class="text-xs text-gray-500">Refresh suggestions</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Input Form -->
        <div class="mt-auto">
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <textarea
                [formControl]="questionControl"
                class="w-full p-3 border border-gray-300 rounded-lg"
                [class.border-red-500]="questionControl.touched && questionControl.invalid"
                placeholder="Type your question here..."
                rows="4"
                (keydown)="onKeyDown($event)"
                data-testid="copilot-question-input"></textarea>

              <div
                *ngIf="questionControl.touched && questionControl.errors?.['minlength']"
                class="text-red-500 text-sm"
                data-testid="copilot-input-error">
                Please enter at least one character
              </div>
            </div>

            <div class="flex justify-end">
              <button
                type="button"
                (click)="onSubmit()"
                class="px-4 py-2 bg-raspberry-darker text-white rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                [disabled]="questionControl.invalid || isCurrentConversationSubmitting"
                data-testid="copilot-submit-button">
                Ask
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
