import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-tags',
  template: `
    <ng-container *ngIf="visibleTags?.length">
      <div
        class="inline-flex flex-wrap gap-0.5"
        data-element="tags-container"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
        <span *ngFor="let tag of visibleTags; let i = index" class="tag" data-element="tag-item">
          {{ tag }}{{ i < visibleTags.length - 1 ? ', ' : '' }}
        </span>
        <span
          (click)="toggleShowAllTags($event)"
          *ngIf="extraTagsCount > 0"
          class="px-0.5 text-xs link-muted"
          data-element="tag-overflow">
          + {{ extraTagsCount }} more tags...
        </span>
      </div>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="showAllTags"
        (overlayOutsideClick)="closePopover()">
        <div
          class="p-4 rounded shadow-lg bg-white border border-smoke max-w-xs max-h-[300px] overflow-y-auto relative"
          data-element="tags-popover">
          <button class="absolute top-3 right-3" (click)="closePopover()"><sbnb-icon icon="cancel"></sbnb-icon></button>
          <div class="mb-2">All Tags</div>
          <div class="flex flex-wrap gap-1">
            <span *ngFor="let tag of tags; let i = index" class="tag" data-element="popover-tag-item">
              {{ tag }}{{ i < tags.length - 1 ? ', ' : '' }}
            </span>
          </div>
        </div>
      </ng-template>
    </ng-container>
  `,
  styles: [
    `
      @import 'variables';

      .tag {
        color: $night;
        @apply text-xs text-night;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent {
  private _tags: string[] = [];
  /** Array of tag strings to display. The component will show up to 5 tags
   * and display a "+ X more tags..." button if there are more than 5 tags. */
  @Input() set tags(value: string[]) {
    this._tags = value ? [...value].sort() : [];
    this.visibleTags = this._tags.slice(0, 5);
    this.extraTagsCount = Math.max(0, this._tags.length - 5);
  }
  get tags(): string[] {
    return this._tags;
  }

  visibleTags: string[] = [];
  extraTagsCount = 0;
  showAllTags = false;

  toggleShowAllTags(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.showAllTags = !this.showAllTags;
  }

  closePopover(): void {
    this.showAllTags = false;
  }
}
