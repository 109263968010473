import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SegmentEvent } from '@app/shared/interfaces';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { BehaviorSubject, combineLatest, from, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ConversationDisplayItem, CopilotService } from '../../../services/copilot/copilot.service';
import { PusherService } from '../../../services/pusher/pusher.service';
import { DialogDeleteGenericComponent } from '../../dialog-delete-generic/dialog-delete-generic.component';
import { ConversationStatus, CopilotConversation } from '../models/copilot.interface';
import { OnDestroyComponent } from '@app/shared/components/on-destroy/on-destroy.component';

enum CopilotFeedbackRating {
  LIKE = 'liked',
  DISLIKE = 'disliked',
}

@Component({
  selector: 'sbnb-copilot-modal',
  templateUrl: './copilot-modal.component.html',
})
export class CopilotModalComponent extends OnDestroyComponent implements OnInit {
  @ViewChild('conversationContainer') conversationContainer: ElementRef;

  // Single source of truth - all data comes from store
  conversations$ = this.copilotService.conversations$.pipe(
    map((conversationMap) => ({
      data: Object.values(conversationMap)
        .filter((entry) => entry.apiData)
        .map((entry) => entry.apiData)
        .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()),
    }))
  );

  // Track active conversation ID only
  currentConversationUuid?: string;
  currentConversationUuid$ = new BehaviorSubject<string | undefined>(undefined);

  // Single source of truth for progress message with default
  progressMessage$ = this.copilotService.progressMessage$(this.currentConversationUuid);

  // Form control and UI state
  questionControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
  isLoading = false;
  questionSuggestions: string[] = [];
  showSuggestions = true;
  openDropdownId: string | null = null;

  CopilotFeedbackRating = CopilotFeedbackRating;

  isCurrentConversationSubmitting = false;

  currentConversation$ = combineLatest([this.copilotService.conversations$, this.currentConversationUuid$])
    .pipe(
      takeUntil(this.destroyed$),
      tap(([conversationMap, uuid]) => {
        const conversation = conversationMap?.[uuid];
        if (!uuid) {
          this.isCurrentConversationSubmitting = false;
        }
        else {
          this.isCurrentConversationSubmitting = conversation?.apiData?.status === ConversationStatus.InProgress || !!conversation?.progressMessage;
        }
      }),
      map(([conversationMap, uuid]) => conversationMap?.[uuid]?.apiData),
    );

  constructor(
    private dialogRef: MatDialogRef<CopilotModalComponent>,
    public copilotService: CopilotService,
    private pusherService: PusherService,
    private dialog: MatDialog,
    private segmentIoService: SegmentIoService,
    public csService: InternalCsService
  ) {
    super();
    // Load initial conversations
    this.copilotService.loadConversationList().subscribe();
    this.loadQuestionSuggestions();
  }

  ngOnInit(): void {
    this.startNewChat();

    // Initialize the Pusher subscription in the service
    this.copilotService.initializePusherSubscription(this.pusherService);
  }

  // Helper method to update conversation UUID and related observables
  private setCurrentConversationUuid(uuid?: string) {
    this.currentConversationUuid = uuid;
    this.currentConversationUuid$.next(uuid);
    this.progressMessage$ = this.copilotService.progressMessage$(uuid);
  }

  startNewChat(): void {
    this.setCurrentConversationUuid(undefined);
    this.questionControl.reset();
    this.showSuggestions = true;
  }

  openConversation(conversation: CopilotConversation): void {
    this.isLoading = true;
    this.setCurrentConversationUuid(conversation.uuid);

    // Load conversation details - store will be updated automatically
    this.copilotService.loadConversationDetails(conversation.uuid).subscribe({
      error: () => {
        this.handleConversationError();
      },
      complete: () => {
        this.isLoading = false;
        this.scrollToBottom();
      },
    });
  }

  private handleConversationError(): void {
    this.setCurrentConversationUuid(undefined);
    this.isLoading = false;
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.questionControl.invalid || this.isCurrentConversationSubmitting) {
      return;
    }

    this.showSuggestions = false;
    const question = this.questionControl.value;
    if (!question) {
      return;
    }

    // Add current conversation to submitting set
    if (this.currentConversationUuid) {
      this.copilotService.markConversationAsSubmitting(this.currentConversationUuid);
    }

    this.copilotService.askQuestion(question, this.currentConversationUuid).subscribe({
      next: (response) => {
        // Set the current conversation UUID to the new or existing conversation
        this.setCurrentConversationUuid(response.data.uuid);
        this.scrollToBottom();
      },
      error: () => {
        if (this.currentConversationUuid) {
          this.copilotService.clearConversationSubmittingState(this.currentConversationUuid);
        }
      },
    });

    this.questionControl.reset();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  loadQuestionSuggestions(): void {
    this.copilotService.getQuestionSuggestions().subscribe({
      next: (response) => {
        this.questionSuggestions = response.data;
      },
      error: () => {
        this.questionSuggestions = [];
      },
    });
  }

  selectSuggestion(suggestion: string): void {
    this.questionControl.setValue(suggestion);
    this.onSubmit();
  }

  deleteConversation(event: Event, conversation: CopilotConversation): void {
    event.stopPropagation();
    this.closeDropdown();

    const dialogRef = this.dialog.open(DialogDeleteGenericComponent, {
      width: '400px',
      data: {
        name: `"${conversation.description}"`,
        action: '',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.confirmDelete(event, conversation);
      }
    });
  }

  confirmDelete(event: Event, conversation: CopilotConversation | null): void {
    if (!conversation) {
      return;
    }

    this.copilotService.deleteConversation(conversation.uuid).subscribe({
      next: () => {
        // If the deleted conversation was the current one, reset the view
        if (this.currentConversationUuid === conversation.uuid) {
          this.startNewChat();
        }
      },
      error: (error) => {
        console.error('Failed to delete conversation:', error);
      },
    });
  }

  toggleDropdown(event: Event, conversationUuid: string): void {
    event.stopPropagation();
    event.preventDefault();

    const currentlyOpenDropdown = this.openDropdownId;
    this.openDropdownId = null;

    if (currentlyOpenDropdown !== conversationUuid) {
      this.openDropdownId = conversationUuid;
    }
  }

  @HostListener('document:click')
  closeDropdown(): void {
    this.openDropdownId = null;
  }

  onFeedbackClick(type: CopilotFeedbackRating, item: ConversationDisplayItem, event: MouseEvent): void {
    if (item.feedback) {
      return;
    }

    item.feedback = type;

    const metadata = {
      conversation_uuid: this.currentConversationUuid,
      message_uuid: item.assistantMessageUuid,
      question: item.question,
      response: item.response,
      $el: event.target,
    };

    if (type === CopilotFeedbackRating.LIKE) {
      this.segmentIoService.track(SegmentEvent.CopilotMessageLiked, metadata);
    } else {
      this.segmentIoService.track(SegmentEvent.CopilotMessageDisliked, metadata);

      setTimeout(() => {
        if (item.feedback === CopilotFeedbackRating.DISLIKE) {
          this.copilotService.enabled$.subscribe(() => {
            this.segmentIoService.track(SegmentEvent.CopilotSurveyTriggered, metadata);
          });
        }
      }, 500);
    }

    // Save feedback in the service
    if (item.assistantMessageUuid && this.currentConversationUuid) {
      this.copilotService
        .saveMessageFeedback(item.assistantMessageUuid, type, this.currentConversationUuid)
        .subscribe();
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.conversationContainer) {
        const element = this.conversationContainer.nativeElement;
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'instant',
        });
      }
    }, 0);
  }

  retryQuestion(item: ConversationDisplayItem): void {
    if (!item.question || !item.userMessageUuid || this.isCurrentConversationSubmitting || !this.currentConversationUuid) { // todo check
      return;
    }

    // Reset error state
    item.error = false;
    item.response = undefined;
    item.progressUpdate = undefined;

    // Add current conversation to submitting set
    this.copilotService.markConversationAsSubmitting(this.currentConversationUuid);

    this.copilotService.retryMessage(item.userMessageUuid, this.currentConversationUuid).subscribe({
      next: (response) => {
        this.scrollToBottom();
      },
      error: () => {
        item.error = true;
        if (this.currentConversationUuid) {
          this.copilotService.clearConversationSubmittingState(this.currentConversationUuid);
        }
      },
    });
  }
}
