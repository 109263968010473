import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AIActions from './AI.actions';
import * as AISelectors from './AI.selectors';

@Injectable()
export class AIFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  generateReplyLoadingState$ = this.store.pipe(select(AISelectors.getGenerateReplyLoadingState));
  generatedReply$ = this.store.pipe(select(AISelectors.getGeneratedReply));

  generatedImprovementLoadingState$ = this.store.pipe(select(AISelectors.getGeneratedImprovementLoadingState));
  generatedImprovement$ = this.store.pipe(select(AISelectors.getGeneratedImprovement));

  constructor(private readonly store: Store) {}

  resetGeneratedReply() {
    this.store.dispatch(AIActions.resetGeneratedReply());
  }

  resetGeneratedImprovement() {
    this.store.dispatch(AIActions.resetGeneratedImprovement());
  }

  generateReply(threadUuid: string, hostUid: string | null, generatedReplyUuid: string | null) {
    this.store.dispatch(AIActions.generateReply({ threadUuid, hostUid, generatedReplyUuid }));
  }

  improveReply(threadUuid: string, existingMessage: string) {
    this.store.dispatch(AIActions.generateImprovement({ threadUuid, existingMessage }));
  }
}
