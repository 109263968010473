export enum ConversationStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  Error = 'error',
}

export interface CopilotProgress {
  message: string;
  status: ConversationStatus;
  conversation_uuid: string;
  question?: string;
  user_message_uuid?: string;
}

export interface CopilotProgressHistory {
  [conversationId: string]: {
    events: CopilotProgress[];
    lastEventTimestamp: number;
  };
}

export interface CopilotMessage {
  id: number;
  uuid: string;
  role: 'user' | 'assistant';
  content: string;
  created_at: string;
  status: ConversationStatus;
}

export interface CopilotConversation {
  id: number;
  uuid: string;
  description: string;
  created_at: string;
  updated_at: string;
  status: ConversationStatus;
  messages?: CopilotMessage[];
}
