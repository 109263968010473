import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BillingFacade } from '@app/shared/+state/billing';
import { UsersFacade } from '@app/shared/+state/users';
import { PosthogFeatureFlag } from '@app/shared/interfaces/lib/feature-flags.interface';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  ConversationStatus,
  CopilotConversation,
  CopilotMessage,
  CopilotProgress,
} from '../../components/copilot/models/copilot.interface';
import { PusherService } from '../pusher/pusher.service';

interface CopilotRequest {
  input: string;
  conversation_id?: string;
  current_page: string;
}

interface QuestionSuggestions {
  data: string[];
}

// Conversation map stores all conversation data
interface ConversationMapEntry {
  apiData: CopilotConversation | null;
  progressMessage: string | null;
}

interface ConversationMap {
  [uuid: string]: ConversationMapEntry;
}

export interface ConversationDisplayItem {
  question: string;
  response?: string;
  progressUpdate?: string;
  error?: boolean;
  feedback?: 'liked' | 'disliked';
  userMessageUuid?: string;
  assistantMessageUuid?: string;
}

const TEMP_MESSAGE_ID = -1;
const TEMP_MESSAGE_UUID = 'pending';

@Injectable({
  providedIn: 'root',
})
export class CopilotService {
  // Conversations store - single source of truth
  private readonly _conversationsSubject = new BehaviorSubject<ConversationMap>({});
  readonly conversations$ = this._conversationsSubject.asObservable();

  // Progress message observable
  readonly progressMessage$ = (conversationUuid?: string) =>
    this.conversations$.pipe(
      map((conversationMap) => {
        return conversationUuid ? conversationMap[conversationUuid]?.progressMessage : null;
      })
    );

  // Expose getter for the subject value
  get conversationsSubject(): BehaviorSubject<ConversationMap> {
    return this._conversationsSubject;
  }

  // Flag to track if initial conversations have been loaded
  private initialConversationsLoaded = false;

  public enabled$ = combineLatest([
    this.posthog.featureEnabled$(PosthogFeatureFlag.Copilot),
    this.billingFacade.isMogulSubscription$,
    this.usersFacade.mogulAiEntitlement$,
  ]).pipe(
    map(([featureEnabled, isMogul, mogulAiEntitlement]) => featureEnabled || isMogul || mogulAiEntitlement?.available),
    shareReplay(1)
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private posthog: PosthogService,
    private billingFacade: BillingFacade,
    private readonly usersFacade: UsersFacade
  ) {}

  /**
   * Load details for a specific conversation
   * Updates the store when the response is received
   */
  loadConversationDetails(uuid: string): Observable<void> {
    console.log('📖 Loading conversation details for:', uuid);

    return this.http.get<{ data: CopilotConversation }>(`${environment.apiUrl}/copilot/conversations/${uuid}`).pipe(
      tap((response) => {
        // Single store update with the new data
        this.updateConversationInStore(uuid, {
          apiData: response.data,
          progressMessage: this.getConversationState()[uuid]?.progressMessage || null,
        });
      }),
      map(() => void 0),
      catchError((error) => {
        console.error('❌ Failed to load conversation details:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Initialize and subscribe to pusher events
   */
  initializePusherSubscription(pusherService: PusherService): void {
    pusherService.copilotProgress
      .pipe(
        // Only process events with valid statuses
        filter((progress: CopilotProgress) => {
          const isValid = [
            ConversationStatus.Completed,
            ConversationStatus.InProgress,
            ConversationStatus.Error,
          ].includes(progress.status);
          return isValid;
        })
      )
      .subscribe((progress: CopilotProgress) => {
        this.handleProgressEvent(progress);
      });
  }

  /**
   * Handle a progress event using the conversations map
   */
  private handleProgressEvent(progress: CopilotProgress): void {
    const currentStore = this.getConversationState();
    const updatedStore = { ...currentStore };
    const conversationEntry = updatedStore[progress.conversation_uuid] || {
      apiData: null,
      progressMessage: null,
    };

    // Update based on event status
    if (conversationEntry.apiData) {
      conversationEntry.apiData.status = progress.status;
    }

    if (progress.status === ConversationStatus.InProgress) {
      conversationEntry.progressMessage = progress.message;
    } else {
      conversationEntry.progressMessage = null;
      if (progress.status === ConversationStatus.Completed) {
        // Load fresh conversation details
        this.loadConversationDetails(progress.conversation_uuid).subscribe(() => {
          setTimeout(() => {
            const element = document.querySelector('[data-testid="copilot-conversation"]');
            if (element) {
              element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth',
              });
            }
          }, 200); // Small delay to ensure content is rendered
        });
      }
    }

    updatedStore[progress.conversation_uuid] = conversationEntry;
    this.updateConversationState(updatedStore);
  }

  /**
   * Get the current progress message for a conversation
   */
  getProgressMessage(conversationUuid: string): string | null {
    const conversations = this.conversationsSubject.value;
    return conversations[conversationUuid]?.progressMessage || null;
  }

  getQuestionSuggestions(): Observable<QuestionSuggestions> {
    return this.http.get<QuestionSuggestions>(`${environment.apiUrl}/copilot/question-suggestions`);
  }

  /**
   * Initiate loading of conversations - should be called once during component initialization
   * Returns Observable for initial load but also updates internal store
   */
  getConversations(): Observable<{ data: CopilotConversation[] }> {
    const startTime = performance.now();

    // If this is the first time loading conversations, mark the flag and fetch from API
    if (!this.initialConversationsLoaded) {
      this.initialConversationsLoaded = true;
      return this.loadConversationList();
    }

    const currentStore = this.conversationsSubject.value;

    // Convert the conversation map to the expected format
    const conversations = Object.entries(currentStore)
      .filter(([_, entry]) => entry.apiData)
      .map(([uuid, entry]) => {
        const detail = entry.apiData;
        return {
          uuid: detail.uuid,
          description: detail.description,
          created_at: detail.created_at,
          updated_at: detail.updated_at,
          status: detail.status,
        } as CopilotConversation;
      });

    return of({ data: conversations });
  }

  /**
   * Internal method that actually makes the API call to load conversations
   * This should only be called:
   * 1. On initial component load
   * 2. After creating a new conversation
   */
  loadConversationList(): Observable<{ data: CopilotConversation[] }> {
    const startTime = performance.now();

    return this.http.get<{ data: CopilotConversation[] }>(`${environment.apiUrl}/copilot/conversations`).pipe(
      tap((response) => {
        const sortedConversations = this.sortConversationsByDate(response.data);

        const newStore: ConversationMap = {};
        sortedConversations.forEach((conv) => {
          const existingProgress = this.getConversationState()[conv.uuid]?.progressMessage;

          newStore[conv.uuid] = {
            apiData: conv,
            progressMessage: existingProgress || null,
          };
        });

        // Update the store
        this.updateConversationState(newStore);
      }),
      catchError((error) => {
        return of({ data: [] });
      })
    );
  }

  /**
   * Ask a question (create a new conversation or add to existing)
   * Will refresh the conversation list after creating a new conversation
   */
  askQuestion(question: string, conversationUuid?: string): Observable<{ data: CopilotConversation }> {
    const currentPage = this.getCurrentPage();
    const payload: CopilotRequest = {
      input: question,
      current_page: currentPage,
      ...(conversationUuid && { conversation_id: conversationUuid }),
    };

    // Set progress message immediately for existing conversation
    if (conversationUuid) {
      const currentStore = this.getConversationState();
      if (currentStore[conversationUuid]?.apiData) {
        currentStore[conversationUuid].apiData.messages[TEMP_MESSAGE_UUID] = this.createTempMessage(question);
        this.updateConversationInStore(conversationUuid, {
          apiData: currentStore[conversationUuid].apiData,
          progressMessage: 'Analyzing your question...',
        });
      }
    }

    return this.http.put<{ data: CopilotConversation }>(`${environment.apiUrl}/copilot/ask`, payload).pipe(
      tap((response) => {
        const uuid = response.data.uuid;
        const currentStore = this.getConversationState();

        // For existing conversations, merge the new data with existing data
        if (conversationUuid && currentStore[conversationUuid]?.apiData) {
          const existingData = currentStore[conversationUuid].apiData;
          // clear the pending message
          delete existingData.messages[TEMP_MESSAGE_UUID];

          const updatedStore = {
            ...currentStore,
            [conversationUuid]: {
              apiData: {
                ...existingData,
                messages: {
                  ...existingData.messages,
                  // Only add new messages that don't exist yet
                  ...Object.fromEntries(
                    Object.entries(response.data.messages || {}).filter(([key]) => !existingData.messages[key])
                  ),
                },
                status: response.data.status,
                updated_at: response.data.updated_at,
              },
              progressMessage: currentStore[conversationUuid].progressMessage,
            },
          };
          this.updateConversationState(updatedStore);
        } else {
          // For new conversations, create a new entry
          const updatedStore = {
            ...currentStore,
            [uuid]: {
              apiData: response.data,
              progressMessage: 'Analyzing your question...',
            },
          };
          this.updateConversationState(updatedStore);
          this.loadConversationList().subscribe();
        }
      })
    );
  }

  /**
   * Create a temporary message
   */
  createTempMessage(question: string): CopilotMessage {
    return {
      role: 'user',
      content: question,
      status: ConversationStatus.InProgress,
      created_at: new Date().toISOString(),
      uuid: TEMP_MESSAGE_UUID,
      id: TEMP_MESSAGE_ID
    }
  }


  /**
   * Retry a message in a conversation
   */
  retryMessage(messageUuid: string, conversationUuid: string): Observable<{ data: CopilotConversation }> {
    const currentPage = this.getCurrentPage();

    // Update progress message immediately
    this.updateConversationInStore(conversationUuid, {
      progressMessage: 'Analyzing your question',
    });

    return this.http
      .put<{ data: CopilotConversation }>(`${environment.apiUrl}/copilot/retry/${messageUuid}`, {
        message_uuid: messageUuid,
        current_page: currentPage,
      })
      .pipe(
        tap((response) => {
          const uuid = response.data.uuid;
          // Update store with retried conversation data
          this.updateConversationInStore(uuid, {
            apiData: response.data,
          });
        }),
        catchError((error) => {
          // Clear progress message on error
          this.updateConversationInStore(conversationUuid, {
            progressMessage: null,
          });
          return throwError(() => error);
        })
      );
  }

  /**
   * Delete a conversation
   * Implements optimistic deletion by removing from store first,
   * then making the API call with rollback on error
   */
  deleteConversation(uuid: string): Observable<{ data: CopilotConversation[] }> {
    // Store current state for potential rollback
    const currentState = this.getConversationState();

    // Optimistically remove from store
    const { [uuid]: removed, ...newStore } = currentState;
    this.updateConversationState(newStore);

    // Make API call
    return this.http
      .delete<{ data: CopilotConversation[] }>(`${environment.apiUrl}/copilot/conversations/${uuid}`)
      .pipe(
        tap(() => {
          // Refresh the list to ensure consistency
          this.loadConversationList().subscribe();
        }),
        catchError((error) => {
          // Restore the previous state if delete fails
          this.updateConversationState(currentState);
          return throwError(() => error);
        })
      );
  }

  /**
   * Gets the current page/route the user is on
   * @returns A string representing the current page
   */
  private getCurrentPage(): string {
    return this.router.url;
  }

  /**
   * Transform conversation detail into a format suitable for UI display
   */
  transformConversationToDisplayFormat(conversationDetail: CopilotConversation): ConversationDisplayItem[] {
    const displayItems: ConversationDisplayItem[] = [];
    // Convert messages to array and sort by ID
    if (conversationDetail?.messages && Object.keys(conversationDetail?.messages)?.length) {
      const messages = Object.values(conversationDetail.messages)
        .filter((message): message is CopilotMessage => message !== null && typeof message === 'object')
        .sort((a, b) => {
          // If either message has id TEMP_MESSAGE_ID, handle special sorting
          if (a.id === TEMP_MESSAGE_ID) return 1;  // a should go to the end
          if (b.id === TEMP_MESSAGE_ID) return -1; // b should go to the end
          // Normal numerical sorting for other cases
          return a.id - b.id;
        });

      // Process messages in pairs (user + assistant)
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];

        if (message.role === 'user') {
          // Create new conversation item for user message
          const item: ConversationDisplayItem = {
            question: message.content,
            userMessageUuid: message.uuid,
          };

          // Look ahead for assistant response
          const assistantMessage = messages[i + 1];
          if (assistantMessage && assistantMessage.role === 'assistant') {
            item.response = assistantMessage.content;
            item.assistantMessageUuid = assistantMessage.uuid;
            i++; // Skip the next message since we've processed it
          }

          displayItems.push(item);
        }
      }
    }
    return displayItems;
  }

  /**
   * Record user feedback for a message
   * @param messageUuid The UUID of the assistant message being rated
   * @param rating The rating given by the user
   * @param conversationUuid The UUID of the conversation
   */
  saveMessageFeedback(messageUuid: string, rating: 'liked' | 'disliked', conversationUuid: string): Observable<any> {
    // Here we could add an API call to save the feedback if needed
    // For now just return an observable that completes successfully
    return of({ success: true });
  }

  /**
   * Create or update a conversation item with a progress message
   * @param conversation The current array of conversation display items
   * @param progressMessage The progress message to display
   * @returns An updated copy of the conversation array
   */
  updateConversationWithProgressMessage(
    conversation: ConversationDisplayItem[],
    progressMessage: string
  ): ConversationDisplayItem[] {
    const updatedConversation = [...(conversation || [])];

    if (updatedConversation.length === 0) {
      // If no conversation items exist, create one
      updatedConversation.push({
        question: '',
        progressUpdate: progressMessage,
      });
    } else {
      // Update the last conversation item
      const lastItem = updatedConversation[updatedConversation.length - 1];
      lastItem.progressUpdate = progressMessage;
    }

    return updatedConversation;
  }

  /**
   * Sort conversations by updated_at in descending order (newest first)
   * @param conversations The conversations to sort
   * @returns A new array with sorted conversations
   */
  sortConversationsByDate(conversations: CopilotConversation[]): CopilotConversation[] {
    if (!conversations || !conversations.length) {
      return [];
    }

    return [...conversations].sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
  }

  private updateConversationState(newState: ConversationMap): void {
    this._conversationsSubject.next(newState);
  }

  private getConversationState(): ConversationMap {
    return this._conversationsSubject.value;
  }

  /**
   * Mark a conversation as being submitted
   */
  markConversationAsSubmitting(conversationUuid: string): void {
    if (!conversationUuid) return;

    const currentState = this.getConversationState();
    if (!currentState[conversationUuid]) return;

    const updatedState = {
      ...currentState,
      [conversationUuid]: {
        ...currentState[conversationUuid],
        apiData: {
          ...currentState[conversationUuid].apiData,
          status: ConversationStatus.InProgress,
        },
      },
    };

    this.updateConversationState(updatedState);
  }

  /**
   * Remove a conversation from the submitting state
   */
  clearConversationSubmittingState(conversationUuid: string): void {
    if (!conversationUuid) return;

    const currentState = this.getConversationState();
    if (!currentState[conversationUuid]) return;

    const updatedState = {
      ...currentState,
      [conversationUuid]: {
        ...currentState[conversationUuid],
        progressMessage: null,
        apiData: {
          ...currentState[conversationUuid].apiData,
          status: ConversationStatus.Error, // When clearing submission, it's usually due to an error
        },
      },
    };

    this.updateConversationState(updatedState);
  }

  /**
   * Helper method to update a specific conversation in the store
   */
  private updateConversationInStore(uuid: string, updates: Partial<ConversationMapEntry>): void {
    const currentState = this.getConversationState();
    const currentEntry = currentState[uuid] || {
      apiData: null,
      progressMessage: null,
    };

    const updatedState = {
      ...currentState,
      [uuid]: {
        ...currentEntry,
        ...updates,
      },
    };

    this.updateConversationState(updatedState);
  }
}
