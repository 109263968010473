<div
  sbnbDragAndDrop
  (filesDropped)="selectFiles($event)"
  class="p-4 w-full h-full bg-smoke-lighter border-dashed border-smoke-darker border flex flex-col">
  <form [formGroup]="fileUploadForm">
    <input
      hidden
      type="file"
      #fileSelector
      formControlName="fileSelection"
      [accept]="acceptedMimeTypes"
      multiple="{{ multiple }}" />
  </form>
  <ng-container *ngIf="selectedFilesState$ | async as files">
    <sbnb-loading-spinner *ngIf="progress"></sbnb-loading-spinner>
    <div *ngIf="(multiple || !files?.length) && !progress" class="flex flex-col gap-2 justify-center items-center">
      <sbnb-icon icon="attachment"></sbnb-icon>
      <span *ngIf="!disabled">
        <ng-container *ngTemplateOutlet="content"></ng-container>
        <ng-container *ngIf="!content">
          <span class="link-emphasized" (click)="openFileSelector()">Click to upload</span>
          or drag and drop
        </ng-container>
      </span>
      <span class="text__xsmall text-night-lighter">
        File Types: {{ acceptedFileTypes }}, max {{ maxSize / 1024 / 1024 }} MB.
      </span>
    </div>
    <div class="flex flex-col gap-2" *ngIf="!progress">
      <div *ngFor="let file of files" class="flex gap-6 items-center">
        <div class="border border-smoke p-2 rounded-md" *ngIf="file.imageSrc">
          <img
            [src]="file.imageSrc"
            alt="File preview"
            class="w-6 h-6 object-cover"
            onerror="this.src = 'assets/iconography/attachment.svg'"
            data-chromatic="ignore" />
        </div>
        <span class="flex flex-col gap-1">
          <span>{{ file.name }}</span>
          <span *ngIf="file.size" class="text-night-lighter">({{ file.size }})</span>
        </span>
        <mat-error *ngIf="!file.isValidSize">Please choose a smaller file.</mat-error>
        <span *ngIf="!disabled" class="flex">
          <sbnb-icon icon="delete-smoke" (click)="remove(file)"></sbnb-icon>
        </span>
      </div>
    </div>
  </ng-container>
</div>
