import { Injectable } from '@angular/core';
import { BillingFacade } from '@app/shared/+state/billing';
import { ApiErrorResponse, ApiResponse } from '@app/shared/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import * as AIActions from './AI.actions';
import { GeneratedReply } from './AI.models';
import { AIService } from './AI.service';

@Injectable()
export class AIEffects {
  generateReply$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AIActions.generateReply),
        mergeMap((action) =>
          this.AIService.generateReply(action.threadUuid, action.hostUid, action.generatedReplyUuid).pipe(
            mergeMap((response: ApiResponse<GeneratedReply>) => {
              return of(
                AIActions.generateReplySuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => {
              return of(AIActions.generateReplyFailure({ response: error }));
            })
          )
        )
      )
  );

  generateImprovement$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AIActions.generateImprovement),
        mergeMap((action) =>
          this.AIService.generateImprovement(action.threadUuid, action.existingMessage).pipe(
            mergeMap((response: ApiResponse<GeneratedReply>) => {
              return of(
                AIActions.generateImprovementSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => {
              return of(AIActions.generateImprovementFailure({ response: error }));
            })
          )
        )
      )
  );

  constructor(
    private readonly actions$: Actions,
    private AIService: AIService,
    private billingFacade: BillingFacade
  ) {}
}
