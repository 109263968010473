import { Component, OnInit, Signal, computed, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { config } from '@app/core/app-config';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { ReservationAgreementService } from '@app/modules/guest-experience/services/reservation-agreements.service';
import { DialogAddNewRentalAgreementToReservationComponent } from '@app/shared/components/dialog-add-new-rental-agreement-to-reservation/dialog-add-new-rental-agreement-to-reservation.component';
import { DialogGuestPortalTasksComponent } from '@app/shared/components/dialog-guest-portal-tasks/dialog-guest-portal-tasks.component';
import { Reservation } from '@app/shared/interfaces';
import {
  GuestPortalTaskAction,
  GuestPortalTaskActionMetadata,
  GuestPortalTaskMetadata,
  GuestPortalTaskStatus,
  GuestPortalTaskStatusMetadata,
  GuestPortalTasks,
} from '@app/shared/interfaces/lib/guest-verification.interface';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { GuestPortalService, VerificationRatingStatus } from '@app/shared/services/guest-portal/guest-portal.service';
import { combineLatest, map } from 'rxjs';
@Component({
  selector: 'sbnb-guest-portal-tasks',
  templateUrl: './guest-portal-tasks.component.html',
})
export class GuestPortalTasksComponent implements OnInit {
  public readonly GUEST_PORTAL_URL = config.GUEST_PORTAL_URL;
  private readonly guestPortalTaskService = inject(GuestPortalService);
  private readonly deviceDetectionService = inject(DeviceDetectionService);
  private readonly dialog = inject(MatDialog);
  private readonly userPermissionsService = inject(UserPermissionsService);
  private readonly agreementService = inject(ReservationAgreementService);

  taskMetadataMap: Record<GuestPortalTaskMetadata['type'], GuestPortalTaskMetadata> =
    this.guestPortalTaskService.taskMetadata;
  taskStatusMetadata: Record<GuestPortalTaskStatus, GuestPortalTaskStatusMetadata> =
    this.guestPortalTaskService.taskStatusMetadata;
  availableActionsMetadata: Record<GuestPortalTaskAction, GuestPortalTaskActionMetadata> =
    this.guestPortalTaskService.guestPortalTaskActionsMetadata;
  guestVerificationStatusMetadata: Record<VerificationRatingStatus, string> =
    this.guestPortalTaskService.guestVerificationStatusMetadata;

  isMobile = toSignal(this.deviceDetectionService.isMobileViewport());

  reservation = input<Reservation>();
  propertyId = input<number>();

  tasks = signal<GuestPortalTasks>([]);

  // We want a simple way to show whether there are any actions required by the guest, or not
  // We'll use a computed signal to determine this based on the status of the tasks
  taskStatus: Signal<'none' | 'issues' | 'completed'> = computed(() => {
    // if all are not requested its none, if all requested are completed its completed, otherwise its issues
    // This might need to become more complex, but for now it'll do
    return this.tasks().every((task) => task.status === 'unrequested')
      ? 'none'
      : this.tasks()
            .filter((task) => task.status !== 'unrequested')
            .every((task) => task.status === 'completed')
        ? 'completed'
        : 'issues';
  });

  availableActions = signal<GuestPortalTaskAction[]>([]);

  ngOnInit() {
    this.guestPortalTaskService.fetchGuestPortalTasks(this.reservation().uuid).subscribe((tasks) => {
      this.tasks.set(tasks);
    });

    this.checkForAvailableActions();
  }

  checkForAvailableActions() {
    // if we're a secondary user without the correct permissions, we cannot fetch agreements or templates, short circuit
    // agreements requires: FeaturePermission::PERM_GUEST_EXPERIENCE.',['.FeaturePermission::PERM_INBOX.'|'.FeaturePermission::PERM_RESERVATION_FINANCIALS.']',
    // templates requires: PERM_GUEST_EXPERIENCE
    combineLatest([
      this.userPermissionsService.hasAccessTo(Features.PERM_GUEST_EXPERIENCE),
      this.userPermissionsService.hasAccessTo(Features.PERM_INBOX),
      this.userPermissionsService.hasAccessTo(Features.PERM_RESERVATION_FINANCIALS),
    ]).subscribe(([hasGuestExperience, hasInbox, hasReservationFinancials]) => {
      if (!hasGuestExperience || !hasInbox || !hasReservationFinancials) {
        this.availableActions.set([]);
        return;
      }

      const agreements$ = this.agreementService.fetchRentalAgreementsForReservation(this.reservation().uuid);
      const templates$ = this.agreementService.fetchReservationAgreementTemplates();

      const availableTemplates$ = combineLatest([templates$, agreements$]).pipe(
        map(([templates, agreements]) =>
          templates.filter(
            (template) =>
              // Filter out templates that have already been requested, and those not matching our property scope
              !agreements.some((agreement) => agreement.template_uuid === template.uuid) &&
              template.property_ids.includes(this.propertyId())
          )
        )
      );

      combineLatest([
        availableTemplates$,
        this.userPermissionsService.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS),
      ]).subscribe(([availableTemplates, hasAccess]) => {
        if (hasAccess && availableTemplates.length > 0) {
          this.availableActions.set(['request-rental-agreement']);
        } else {
          this.availableActions.set([]);
        }
      });
    });
  }

  openDetailsDialog(initialTab: GuestPortalTaskMetadata['type']) {
    const dialog = this.dialog.open(DialogGuestPortalTasksComponent, {
      data: {
        initialTab,
        reservation: this.reservation(),
        propertyId: this.propertyId(),
      },
      width: this.isMobile() ? '100%' : '80vw',
      height: this.isMobile() ? '100%' : '80vh',
      maxWidth: this.isMobile() ? '100vw' : '80vw',
      panelClass: 'dialog-guest-portal-tasks',
    });

    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  handleAction(action: GuestPortalTaskAction) {
    switch (action) {
      case 'request-rental-agreement':
        this.openRequestNewRentalAgreementDialog();
        break;
      default:
        console.error('Unknown action:', action);
    }
  }

  openRequestNewRentalAgreementDialog() {
    const dialog = this.dialog.open(DialogAddNewRentalAgreementToReservationComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      autoFocus: false,
      data: {
        reservationUuid: this.reservation().uuid,
        propertyId: this.propertyId(),
        guestName: this.reservation().guest,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }
}
